import React, { useRef } from 'react';
import PlatformSection from '../PlatformSection';
import TestimonialSection from '../TestimonialSection';
import { HomepagePlatformSection } from '@/shared/types/homepage';
import './index.less';

interface IProps {
  section: HomepagePlatformSection;
}

const TabSection = ({ section }: IProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div ref={ref} id={section.id} className="tab-section">
      <PlatformSection section={section} />
      <TestimonialSection testimonial={section.testimonial} />
    </div>
  );
};

export default TabSection;
