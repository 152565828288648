import React from 'react';
import { FeaturedBrandsSliderItem } from '@/types/homepage';
import { imgix } from '@/shared/utils/utils';
import './index.less';
import { ImgixFitType } from '@/shared/types/imgix';

interface IProps {
  brand: FeaturedBrandsSliderItem;
}

const BrandCard = ({ brand: initBrand }: IProps) => {
  const { image, name, logo, width, height, brand } = initBrand;

  return (
    <div className="brand-card">
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        srcSet={
          imgix(image, { q: 90, w: 275, h: 290, fit: ImgixFitType.CROP }) +
          ' 1x, ' +
          imgix(image, { q: 45, w: 550, h: 580, fit: ImgixFitType.CROP }) +
          ' 2x'
        }
        src={imgix(image, { q: 90, w: 275, h: 290, fit: ImgixFitType.CROP })}
        alt={name}
        title={name}
        width={275}
        height={290}
        loading="lazy"
      />
      <div className="info">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className="brand-logo"
          srcSet={
            imgix(logo, { q: 90, w: width, h: height, fit: ImgixFitType.CROP }) +
            ' 1x, ' +
            imgix(logo, { q: 45, w: width * 2, h: height * 2, fit: ImgixFitType.CROP }) +
            ' 2x'
          }
          src={imgix(logo, { q: 90, w: width, h: height, fit: ImgixFitType.CROP })}
          alt={name}
          title={name}
          width={width}
          height={height}
          loading="lazy"
        />
        <div className="text">
          <p className="brand">{brand}</p>
          <p className="name">{`Founded by ${name}`}</p>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
