import React from 'react';
import { imgix } from '@/shared/utils/utils';
import { HomepageTestimonial } from '@/shared/types/homepage';
import classNames from 'classnames';
import './index.less';

interface IProps {
  testimonial: HomepageTestimonial;
}

const TestimonialSection = ({ testimonial }: IProps) => {
  const { author, bgColor, image, isTextLeft, review, title } = testimonial;

  return (
    <div className={classNames('testimonial-section', { reverse: !isTextLeft })}>
      <div className="content-max">
        <div className="text-section" style={{ backgroundColor: bgColor }}>
          <h3 className="title" style={{ color: title.color }}>
            {title.text}
          </h3>

          <p className="description" style={{ color: review.color }}>
            {review.text}
          </p>

          <div className="author">
            <p className="author-name" style={{ color: review.color }}>
              - {author.name}
            </p>
            <p className="author-brand" style={{ color: review.color }}>
              {author.brand}
            </p>
          </div>
        </div>

        <div className="secondary-section image">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            loading="lazy"
            src={imgix(image, { q: 90, dpr: 1, w: 420 })}
            srcSet={[
              imgix(image, { q: 90, dpr: 1, w: 420 }) + ' 1x',
              imgix(image, { q: 45, dpr: 2, w: 420 }) + ' 2x',
              imgix(image, { q: 22, dpr: 3, w: 420 }) + ' 3x',
            ].join(', ')}
            alt={title.text}
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
