import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import _ from 'lodash';
import classNames from 'classnames';
import './index.less';
import { imgix } from '@/shared/utils/utils';

const SupplierProfileSection = () => {
  const [isInView, setIsInView] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsInView(entry.isIntersecting));
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  const listItems: string[][] = [
    ['New product development', 'Discovering lower cost suppliers', 'Creating PR boxes', 'Product collabs'],
    [
      'Access 1200+ vetted factories',
      'Pre-negotiated shipping & freight rates',
      'Vetted creative service providers',
      'Access to specialists',
    ],
  ];
  return (
    <div className="supplier-profile-section">
      <div className="content-max">
        <div className="features">
          <h2 className="title">Built for entrepreneurs & brands who value time and savings</h2>
          <div className="feature-list">
            {_.map(listItems, (section: string[], i: number) => {
              return (
                <div key={`feature-list-section-${i}`} className="feature-list-section">
                  {_.map(section, (item: string, j: number) => {
                    return (
                      <div key={`feature-list-item-${j}`} className="feature-list-item">
                        <Image
                          src="/images/homepage-v2/check-circle-spark.svg"
                          width={24}
                          height={24}
                          alt="New product development"
                        />
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

          <div ref={ref} className={classNames('image', { 'in-view': isInView })}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              srcSet={[
                imgix('https://static.pietrastudio.com/public/file_uploads/828aa46ad84f10d3eedaa35ac92e8ba2.png', {
                  w: 975,
                  dpr: 1,
                  q: 80,
                }) + ' 1x',
                imgix('https://static.pietrastudio.com/public/file_uploads/828aa46ad84f10d3eedaa35ac92e8ba2.png', {
                  w: 975,
                  dpr: 2,
                  q: 40,
                }) + ' 2x',
                imgix('https://static.pietrastudio.com/public/file_uploads/828aa46ad84f10d3eedaa35ac92e8ba2.png', {
                  w: 975,
                  dpr: 3,
                  q: 20,
                }) + ' 3x',
              ].join(', ')}
              src={imgix('https://static.pietrastudio.com/public/file_uploads/828aa46ad84f10d3eedaa35ac92e8ba2.png', {
                w: 975,
              })}
              alt="Supplier Profile"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierProfileSection;
