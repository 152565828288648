import React, { useState } from 'react';
import classNames from 'classnames';
import { HomepagePlatformSection, HomepagePlatformSectionButton } from '@/shared/types/homepage';
import { imgix } from '@/shared/utils/utils';
import './index.less';
import VimeoLightbox from '../VimeoLightbox';
import Link from 'next/link';
import WithPietraComparator from '../WithPietraComparator';
import { map } from 'lodash';

interface IProps {
  section: HomepagePlatformSection;
}
const TabSection = ({ section }: IProps) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState<boolean>(false);

  const { preTitle, title, buttons, description, vimeo } = section;

  return (
    <>
      <div className={classNames('platform-section', { reverse: !section.isTextLeft })}>
        <div className="content-max">
          <div className="text-section">
            <span className="pre-title" style={{ color: preTitle.color }}>
              {preTitle.text}
            </span>
            <h2 className="title" style={{ color: title.color }}>
              {title.text}
            </h2>
            <p className="description" style={{ color: description.color }}>
              {description.text}
            </p>
            <div className="btn-container">
              {map(buttons, (button: HomepagePlatformSectionButton) => {
                return (
                  <Link
                    key={button.text}
                    href={button.path}
                    className="action-btn"
                    style={{
                      backgroundColor: button.bgColor,
                      border: `1px solid ${button.borderColor}`,
                    }}
                    onClick={() => {}}
                  >
                    <span
                      style={{
                        color: button.color,
                      }}
                    >
                      {button.text}
                    </span>
                  </Link>
                );
              })}
            </div>

            <WithPietraComparator {...section.withPietraComparator} />
          </div>

          <div className="secondary-section image">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              loading="lazy"
              src={imgix(section.image, { q: 90, dpr: 1, w: 575 })}
              srcSet={[
                imgix(section.image, { q: 90, dpr: 1, w: 575 }) + ' 1x',
                imgix(section.image, { q: 45, dpr: 2, w: 575 }) + ' 2x',
                imgix(section.image, { q: 22, dpr: 3, w: 575 }) + ' 3x',
              ].join(',')}
              alt={title.text}
              onClick={() => setIsLightboxOpen(true)}
            />
          </div>
        </div>
      </div>
      <VimeoLightbox vimeo={vimeo} open={isLightboxOpen} onCancel={() => setIsLightboxOpen(false)} />
    </>
  );
};

export default TabSection;
