import { SeoMeta, SEO_META_PAGES } from '@/shared/types/seo';
import { defaultPietraJsonLd, registerJsonLd } from './jsonld';

export const SEO_META: SeoMeta = {
  [SEO_META_PAGES.EXISTING_BRANDS]: {
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.NEW_BRANDS]: {
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.PIETRA_SHOPIFY]: {
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.ERROR]: {
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.HOME]: {
    jsonld: defaultPietraJsonLd,
  },
  [SEO_META_PAGES.PIETRA_PARTNERS]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.PRICING]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.SELL_ON_PIETRA]: {
    jsonld: () => [defaultPietraJsonLd(), registerJsonLd()],
  },
  [SEO_META_PAGES.TERMS_OF_SERVICE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.PRIVACY_POLICY]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.FULFILLMENT_SERVICES]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.ORDER_POLICIES]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_FINDING_CLOTHING_SUPPLIERS]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_ATHLEISURE_BRAND]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_CLOTHING_BRAND]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_AN_EYESHADOW_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_HAIR_PRODUCTS_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_LIP_GLOSS_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_MAKEUP_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_SKINCARE_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_CANDLE_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_ATHLEISURE_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_JEWELRY_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_SUNGLASSES_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_START_A_LEATHER_GOODS_LINE]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_FINDING_ATHLEISURE_SUPPLIERS]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_OPEN_PIETRA_STOREFRONT]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ETSY]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_ALIBABA]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_CHANNEL_ADVISOR]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LANDING_ALTERNATIVE_TO_SHIPBOB]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.IDEATION]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.LOGISTICS]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.SALES]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.BUSINESS_KIT]: {
    jsonld: () => defaultPietraJsonLd(),
  },
  [SEO_META_PAGES.DESIGN_STUDIO]: {
    jsonld: () => defaultPietraJsonLd(),
  },
};
