import React from 'react';
import _ from 'lodash';
import BrandCard from '../BrandCard';
import { CREATORS } from '@/shared/utils/homepage-util';
import { FeaturedBrandsSliderItem } from '@/types/homepage';
import './index.less';

interface IProps {}

const BrandGrid = ({}: IProps) => {
  return (
    <div className="brand-grid">
      <div className="content-max">
        <h2 className="title">Trusted by many</h2>
        <div className="brands">
          {_.map(CREATORS, (brand: FeaturedBrandsSliderItem) => {
            return <BrandCard key={brand.brand} brand={brand} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default BrandGrid;
