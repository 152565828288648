import React from 'react';
import Image from 'next/image';
import _ from 'lodash';
import './index.less';
import { CheckOutlined, CloseOutlined } from '@/components/Common/Icons';

interface IProps {
  titleGradient: string;
  backgroundColor: string;
  borderColor: string;
  withBullets: string[];
  withoutBullets: string[];
}

const WithPietraComparator = ({ titleGradient, backgroundColor, borderColor, withBullets, withoutBullets }: IProps) => {
  const renderBullet = (text: string, isWithPietraSection?: boolean) => {
    return (
      <div className="bullet" key={text}>
        {isWithPietraSection ? (
          <CheckOutlined className="close-dialog" style={{ color: '#000000' }} />
        ) : (
          <CloseOutlined className="close-dialog" style={{ color: 'var(--pietra-grey-2)' }} />
        )}
        <span className="text">{text}</span>
      </div>
    );
  };

  return (
    <div className="with-pietra-comparator">
      <div className="with-pietra" style={{ backgroundColor, borderColor: 'transparent' }}>
        <div className="title-container">
          <span className="with" style={{ background: titleGradient }}>
            With
          </span>
          <Image
            src="/images/logos/pietra-logo-black.svg"
            width={62}
            height={16}
            alt="Pietra"
            title="Pietra"
            loading="eager"
          />
        </div>
        <>{_.map(withBullets, (bulletText) => renderBullet(bulletText, true /*isWithPietraSection*/))}</>
      </div>
      <div className="without-pietra" style={{ borderColor: 'transparent' }}>
        <span className="title">Without Pietra</span>
        <>{_.map(withoutBullets, (bulletText) => renderBullet(bulletText))}</>
      </div>
    </div>
  );
};

export default WithPietraComparator;
