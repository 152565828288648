import SectionTabs from '@/components/Home/HomepageV2/Tabs';
import { HOMEPAGE_TAB_SECTIONS, SECTION_TABS } from '@/shared/constants/homepage-constants';
import { map } from 'lodash';
import { HomepagePlatformSection } from '@/shared/types/homepage';
import TabSection from '@/components/Home/HomepageV2/TabSection';
import BrandGrid from '@/components/Home/HomepageV2/BrandGrid';
import SupplierProfileSection from '@/components/Home/HomepageV2/SupplierProfileSection';
import StartNow from '@/components/Home/HomepageV2/StartNow';
import React, { MouseEvent, useCallback, useRef } from 'react';
import HomePageIntro from '@/components/Home/HomepageV3/Intro';
import { HOME_PAGE_BRAND_VIDEO_LIST } from '@/shared/constants/landing-pages';
import { BrandVideos } from '@/components/Home';
import './index.less';

const HomepageV3 = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div className="pietra-home-page-v3">
      <HomePageIntro />
      <div className="pietra-home-page-v3-content">
        <BrandVideos brandList={HOME_PAGE_BRAND_VIDEO_LIST} />
        <div className="text-section-wrap">
          <div className="text-section-content">
            <div className="title">The perfect complement to your storefront.</div>
            <div className="description">
              Manage everything that isn&#39;t in your Shopify. Production, order fulfillment, and access essential
              marketing tools, all included with your Pietra Membership.
            </div>
          </div>
        </div>
        <SectionTabs tabs={SECTION_TABS} />
        <div className="homepage-tab-sections">
          {map(HOMEPAGE_TAB_SECTIONS, (section: HomepagePlatformSection) => {
            return <TabSection key={section.id} section={section} />;
          })}
        </div>
        <div ref={ref} className="homepage-bottom-content">
          <BrandGrid />
          <SupplierProfileSection />
          <StartNow />
        </div>
      </div>
    </div>
  );
};

export default HomepageV3;
