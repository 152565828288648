import React, { useCallback } from 'react';
import './index.less';
import { ArrowRight } from '@/components/Common/Icons';
import { useRouter } from 'next/router';
import { useConfigProvider } from '@/lib/context';

const FulfillmentBanner = ({ onFulfillment = false }: { onFulfillment?: boolean }) => {
  const router = useRouter();
  const { isMobile, screenSize } = useConfigProvider();

  const goToFulfillment = useCallback(() => {
    router.push('/platform/logistics-and-fulfillment');
  }, [router]);

  const goToDemoSignup = useCallback(() => {
    router.push('/platform/logistics-and-fulfillment/signup');
  }, [router]);

  return (
    <div className="deal-banner" onClick={onFulfillment ? goToDemoSignup : goToFulfillment}>
      <div className="text-container">
        {screenSize?.width > 720 && (
          <div className="black-friday-pill">
            <div className="text">PREPARE FOR BLACK FRIDAY</div>
          </div>
        )}
        <div className="text">
          {screenSize?.width <= 720
            ? 'Move in and save 60%+ on fulfillment'
            : 'Cut fulfillment, shipping, and logistics costs by up to 60% with a Pietra membership.'}
        </div>
      </div>
      <div className="cta">
        {screenSize?.width > 720 && <div className="text">Move in</div>}
        <ArrowRight className="right-icon" />
      </div>
    </div>
  );
};

export default FulfillmentBanner;
