import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useRouter } from 'next/router';
import './index.less';

const StartNow = () => {
  const router = useRouter();

  const goToPreviewFlow = useCallback(() => {
    router.push('/existing-brands/signup');
  }, [router]);

  const goToSignup = useCallback(() => router.push('/sell-on-pietra/register'), [router]);

  return (
    <div className="start-now">
      <div className="content-max">
        <div className="text-section">
          <h2 className="title">Make it happen now with Pietra</h2>
          <p className="description">Join the more than 250,000 brands & entrepreneurs on Pietra</p>
          <div className="btn-container">
            <Button className="start-now-btn create-account-btn" onClick={goToSignup}>
              <span>Create free account</span>
            </Button>

            <Button className="start-now-btn preview-pietra-btn" onClick={goToPreviewFlow}>
              <span>Book a demo</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNow;
